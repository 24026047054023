import React from 'react';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import { format, formatDistance } from 'date-fns';
import { srLatn, hr } from 'date-fns/locale';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Gallery from '../components/shared/Gallery';
import type { Category, ImageWithAlt } from '../types';

interface ProjectMetadata {
  startDate: string;
  endDate: string;
  investor: string;
  location: string;
}

interface Props {
  data: {
    sanityProject: {
      category: Partial<Category>;
      title: string;
      metadata: ProjectMetadata;
      image: ImageWithAlt;
      video: {
        asset: {
          mimeType: string;
          url: string;
        };
      };
      _rawDescription: unknown;
      gallery: {
        images: ImageWithAlt[];
      };
      openGraph: {
        title?: string;
        description: string;
        _rawImage: {
          _type: string;
          asset: {
            _ref: string;
            _type: string;
          };
        };
      };
    };
  };
}

const SingleProject: React.FC<Props> = ({ data: { sanityProject } }) => {
  if (!sanityProject) return null;

  return (
    <Layout>
      <SEO
        description={sanityProject.openGraph?.description}
        image={sanityProject.openGraph?._rawImage || sanityProject.image}
        title={sanityProject.openGraph?.title || sanityProject.title}
      />

      <div className="space-y-28 sm:space-y-36">
        <div>
          <section className="px-4 lg:container">
            <div className="mb-4 mt-8 sm:mt-12">
              <span className="font-medium tracking-tight text-gray-500 hover:text-blue-500 hover:underline sm:text-lg">
                <Link to="/projekti/">Projekti</Link>
              </span>
              <span className="mx-2">/</span>
              <span className="font-medium tracking-tight text-gray-500 hover:text-blue-500 hover:underline sm:text-lg">
                <Link
                  to={`/projekti?filter=${sanityProject.category.name.toLowerCase()}`}
                >
                  {sanityProject.category.name}
                </Link>
              </span>
            </div>

            <h1 className="text-3xl font-medium leading-normal tracking-tight text-gray-800 md:text-4xl lg:text-5xl">
              {sanityProject.title}
            </h1>
          </section>

          <section className="mt-8 space-y-5 lg:container sm:mt-16 md:flex md:flex-row-reverse md:space-y-0">
            <div className="md:w-2/3">
              <div className="aspect-h-2 aspect-w-3 relative">
                {sanityProject.video ? (
                  <video
                    className="!absolute object-cover"
                    controls
                    playsInline
                    poster={sanityProject.image.asset.url}
                    preload="auto"
                  >
                    <source
                      src={sanityProject.video.asset.url}
                      type={sanityProject.video.asset.mimeType}
                    />
                  </video>
                ) : (
                  <GatsbyImage
                    alt={sanityProject.image.alt}
                    className="!absolute object-cover"
                    fluid={sanityProject.image.asset.fluid}
                  />
                )}
              </div>
            </div>

            <dl className="container space-y-6 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 md:flex md:w-1/3 md:flex-col">
              <div className="mt-auto">
                <dt className="font-semibold tracking-tight text-gray-400">
                  Početak projekta
                </dt>
                <dd className="text-lg text-gray-900">
                  {format(
                    new Date(sanityProject.metadata.startDate),
                    'MMMM yyyy.',
                    { locale: srLatn },
                  )}
                </dd>
              </div>

              <div>
                <dt className="font-semibold tracking-tight text-gray-400">
                  Kraj projekta
                </dt>
                <dd className="text-lg text-gray-900">
                  {format(
                    new Date(sanityProject.metadata.endDate),
                    'MMMM yyyy.',
                    { locale: srLatn },
                  )}{' '}
                  (
                  {formatDistance(
                    new Date(sanityProject.metadata.endDate),
                    new Date(sanityProject.metadata.startDate),
                    { locale: hr },
                  )}
                  )
                </dd>
              </div>

              <div>
                <dt className="font-semibold tracking-tight text-gray-400">
                  Lokacija
                </dt>
                <dd className="text-lg text-gray-900">
                  {sanityProject.metadata.location}
                </dd>
              </div>

              {sanityProject.metadata.investor && (
                <div>
                  <dt className="font-semibold tracking-tight text-gray-400">
                    Investitor
                  </dt>
                  <dd className="text-lg text-gray-900">
                    {sanityProject.metadata.investor}
                  </dd>
                </div>
              )}
            </dl>
          </section>
        </div>

        {sanityProject._rawDescription && (
          <section className="container">
            <BlockContent
              blocks={sanityProject._rawDescription}
              className="space-y-6 leading-normal md:ml-auto md:w-2/3 md:text-xl lg:mr-16 lg:w-1/2"
            />
          </section>
        )}

        {sanityProject.gallery && (
          <section className="mt-16">
            <Gallery
              className="lg:container"
              images={sanityProject.gallery.images}
            />
          </section>
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Project($slug: String!) {
    sanityProject(slug: { current: { eq: $slug } }) {
      category {
        name
      }
      title
      metadata {
        startDate
        endDate
        investor
        location
      }
      video {
        asset {
          url
          mimeType
        }
      }
      image {
        alt
        asset {
          _id
          url
          fluid {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      _rawDescription(resolveReferences: { maxDepth: 5 })
      gallery {
        images {
          alt
          asset {
            _id
            fluid {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
      openGraph {
        title
        description
        _rawImage
      }
    }
  }
`;

export default SingleProject;
