import React, { useEffect, useState } from 'react';
import GatsbyImage from 'gatsby-image';
import clsx from 'clsx';

import type { ImageWithAlt } from '../../types';
import useKeyPress from '../../hooks/useKeyPress';

interface Props {
  className?: string;
  images: Array<ImageWithAlt>;
}

const Gallery: React.FC<Props> = ({ className, images }) => {
  const [clickedFigureIndex, setClickedFigureIndex] = useState<number>(-1);

  useKeyPress('Escape', () => {
    if (images.length && clickedFigureIndex !== -1) {
      handleFigureClick(-1);
    }
  });

  useKeyPress('ArrowLeft', () => {
    if (images.length && images.length > 1 && clickedFigureIndex !== -1) {
      setClickedFigureIndex(
        (prevState) => (prevState - 1 + images.length) % images.length,
      );
    }
  });

  useKeyPress('ArrowRight', () => {
    if (images.length && images.length > 1 && clickedFigureIndex !== -1) {
      setClickedFigureIndex((prevState) => (prevState + 1) % images.length);
    }
  });

  function handleFigureClick(index: number) {
    document.body.classList.toggle('image-zoomed');

    if (clickedFigureIndex !== -1) {
      setClickedFigureIndex(-1);
    } else {
      setClickedFigureIndex(index);
    }
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove('image-zoomed');
    };
  }, []);

  return (
    <section className={clsx([className, 'gallery'])}>
      {images.map(({ alt, asset }, index) => (
        <figure
          className={clsx([clickedFigureIndex === index && 'zoomed'])}
          key={asset._id}
          onClick={() => handleFigureClick(index)}
        >
          <GatsbyImage alt={alt} fluid={asset.fluid} />
        </figure>
      ))}
    </section>
  );
};

export default Gallery;
