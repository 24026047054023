import { useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useKeyPress(targetKey: string, handler: () => void) {
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    function upHandler({ key }) {
      const { nodeName } = document.activeElement;
      if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') return;

      if (key === targetKey) {
        handlerRef.current();
      }
    }

    document.addEventListener('keyup', upHandler);

    return () => {
      document.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);
}
